import React, { useEffect, useState } from 'react';
import loc from './css/about-us-statistics.module.css'
import styles from './css/subpages.module.css'
import stats from './css/about-us-statistics.module.css'
import Header from './components/header'
import Chart from "react-google-charts";
import downloadIcon from './images/download.png'
import arrows from './images/down-arrows-navyblue.png'
import gsap from 'gsap';
import getData from './utils/fetchdata'

const MobileChart = (props) => 
{
//const { state } = props
const [loading, setLoading] = useState(false)
const [state, setState] = useState(props)
const [showGroup, setShowGroup] = useState(false)

useEffect(() => {

const fetchData = async () => {
  const resp2 = await getData(`${process.env.PUBLIC_URL}/apidata/turnover.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      let arr = []
      
      data.forEach(x => 
        arr.push({...x.acf})
      )
      return {turnover: [...arr]}
    }
  })

  let chartData = []
  chartData[0] = ['Year', 'CNUD-EFCO | GFT', 'OMCO', 'BMT Aerospace', 'IGW', 'VCST']
  for(let i = 0; i < resp2.turnover.length; i++)
  {
  	chartData.push([resp2.turnover[i].year, parseInt(resp2.turnover[i]["cnud-efco_gft"]), 
  		parseInt(resp2.turnover[i]["omco_turnover"]), parseInt(resp2.turnover[i]["bmt_aerospace_turnover"]),
  		parseInt(resp2.turnover[i]["igw"]), parseInt(resp2.turnover[i]["vcst"]),
  		//parseInt(resp2.turnover[i]["bmt_drive_solutions_turnover"]),
  	])
  }

  let chartDataGroup = []
  chartDataGroup[0] = ['Year', 'BMT GROUP']
  for(let i = 0; i < resp2.turnover.length; i++)
  {
  	chartDataGroup.push([resp2.turnover[i].year, parseInt(resp2.turnover[i]["bmt_total_group_turnover"]),
  	])
  }

  setState({...resp2, 
  	yearsIndex: resp2.turnover.length ? (resp2.turnover.length - 1) : 0,
    chartData: [...chartData], chartDataGroup: [...chartDataGroup]
  	})
}

fetchData();
setLoading(true)
}, []);

useEffect(() => {
  gsap.timeline().from(`.${styles.title}`, {
    duration:0.7,
      stagger: 0.1,
      x: "-60vw",
      y: "10",
      ease: "power4.out"
  }, 0)
.from(".timebar", {
      duration:0.7,
      stagger: 0.1,
      x: "-60vw",
      y: "10",
      ease: "power4.out"
  }, 0)
.from(".therg", {
      duration:1,
      stagger: 0.3,
      x: "400",
      y: "400",
      ease: "power4.out"
  }, 0)
/*.from(`.${styles.downloadButton}`, {
      duration:0.7,
      stagger: 0.1,
      x: "40vw",
      y: "-40vh",
      ease: "power4.out"
  }, 0)*/
}, [])

return(
<>
{loading &&
<div style={{overflow: "hidden"}}>
<div className="container-fluid" style={{padding: "0px"}}>
	<Header/>

  <a href='/about-us-vision'>
    <img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)", height: "25px"}} alt="bmt aerospace arrows"/>
  </a>

  	<div className={`row align-items-center timebar ${loc.topMargin}`}>
		<div className="col d-flex flex-column justify-content-center align-items-center">
			<h1 className={`${styles.title} ${styles.blue}`} style={{fontWeight: '600'}}>
      			Total Group Turnover
      		</h1>
    	</div>
	</div>

	<div className={stats.chartWrap}>
	  <div className={stats.chart}>
	    <div className="d-flex flex-column justify-content-center therg" style={{ minHeight: "300px", marginLeft: 'auto', marginRight: 'auto' }}>
	      	<div style={{marginLeft: "auto", marginRight: "auto", backgroundColor: "white", borderStyle:"solid", borderColor: "#0098d9", padding: "3px"}}>
	      		<button onClick={() => setShowGroup(false)} style={{backgroundColor: showGroup ? "white" : "#0089d9", color: showGroup ? "#0089d9" : "white", minWidth: "100px"}} type="button" className="btn shadow-none">DIVISIONS</button>
	      		<button onClick={() => setShowGroup(true)} style={{color: !showGroup ? "#0089d9" : "white", backgroundColor: !showGroup ? "white" : "#0089d9", minWidth: "100px"}} type="button" className="btn shadow-none">GROUP</button>
	      	</div>

	      	<div style={{marginLeft: "auto", marginRight: "auto"}}>
	      	{ showGroup ? 
	      		<Chart
				  width={'350px'}
				  height={'350px'}
				  chartType="ColumnChart"
				  loader={<div>Loading Chart</div>}
				  data={state.chartDataGroup && state.chartDataGroup}
				  options={{
				    // Material design options
				    chart: {
				      title: 'Total Group Turnover',
				      //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
				    },
					legend: { position: 'bottom' },
				    colors: ['#64AB80'],
				    isStacked: true,
				    hAxis: {
				      title: '',
				      minValue: 0,
				    },
				    vAxis: {
				      title: '',
				    },
				  }}
				  // For tests
				  rootProps={{ 'data-testid': '2' }}
				/>
	      		:
		      	<Chart
				  width={'350px'}
				  height={'350px'}
				  chartType="ColumnChart"
				  loader={<div>Loading Chart</div>}
				  data={state.chartData && state.chartData}
				  options={{
				    // Material design options
				    chart: {
				      title: 'Total Group Turnover',
				      //subtitle: 'Sales, Expenses, and Profit: 2014-2017',
				    },
				    legend: { position: 'bottom' },
				    colors: ['#BEBEBE', '#00B4EA', '#836CAC', '#fbb600', '#c5253d', '#0D1134'],
				    isStacked: true,
				    hAxis: {
				      title: '',
				      minValue: 0,
				    },
				    vAxis: {
				      title: '',
				    },
				  }}
				  // For tests
				  rootProps={{ 'data-testid': '3' }}
				/>
			}
			</div>
	    </div>
	  </div>  
	</div>    


    <div className="d-flex justify-content-center">
    <a href={state.turnover && state.turnover[state.yearsIndex] && state.turnover[state.yearsIndex].annual_report_link}>
      <button className={`${styles.downloadButton} ${styles.downloadButtonText}`} type="submit">
        <img className={`${styles.downloadIcon}`} src={downloadIcon} alt="download"/>
        {`  `}Annual report {state.turnover && state.turnover[state.yearsIndex] && state.turnover[state.yearsIndex].year}
      </button>
    </a>  
    </div>
  </div>

    <div className={`d-flex flex-row mt-auto justify-content-center align-middle align-items-center pb-3`} style={{paddingTop: "50px"}}>
      <div className="col" style={{padding: "0px"}}>
      </div>
      <div className="col" style={{padding: "0px", position: "relative", bottom: "10px"}}>
        <a href="/bmt-global-presence" className={`${styles.downArrowsMobile} ${styles.genericPageArrows}`}>
          <img src={arrows} alt="down arrows"/>   
        </a>
      </div>
    </div>
  
</div>
}
</>
)}

export default MobileChart